<template>
  <main
    :class="menuCollapsed ? 'md:pl-[140px]' : 'md:pl-[284px]'"
    class="pl-6 pr-6 pt-[70px] pb-[140px] text-sm/[1.42] lg:text-lg/[1.38] duration-300"
  >
    <h1 class="mb-9 text-[40px] font-bold leading-[1.1] lg:text-[80px]">Rates</h1>
    <Form @submit="ratesSubmit" class="form">
      <section class="space-y-8">
        <!-- Adapt table for mobile devices -->
        <div class="overflow-hidden" id="headerdiv">
          <table id="headertable" class="w-full">
            <thead>
              <tr
                class="grid grid-cols-[2fr_repeat(11,_1fr)] items-center gap-x-2 border-b border-silver-fog pb-1.5 text-ghost-gray"
              >
                <th class="text-left font-normal">Cars</th>
                <th class="text-left font-normal">Surcharge Start</th>
                <th class="text-left font-normal">Surcharge End</th>
                <th class="text-left font-normal">
                  Surcharge <br />
                  Plus/Minus
                </th>
                <th class="text-left font-normal">Surcharge Coefficient(%)</th>
                <th class="font-normal">Status</th>
                <th class="font-normal">Basic Exit Fee</th>
                <th class="font-normal">Last Minute Fee</th>
                <th class="font-normal">Last Second Fee</th>
                <th class="font-normal">Price per km</th>
                <th class="font-normal">Price per hour</th>
                <th class="font-normal">Extra km rate</th>
              </tr>
            </thead>
          </table>
        </div>
        <div
          class="overflow-y-scroll max-h-[100vh]"
          onscroll="document.getElementById('headerdiv').scrollLeft = this.scrollLeft;"
        >
          <table id="bodytable" class="grid w-full gap-y-2 text-center">
            <tbody id="fix" class="space-y-2 scroll-smooth">
              <tr
                v-for="car in rates"
                :key="car.class_id"
                class="grid grid-cols-[2fr_repeat(11,_1fr)] items-center gap-x-2"
              >
                <td class="py-2.5 text-left">{{ car.class_name.toString().replace(' ', '-') }}</td>
                <td class="h-full">
                  <Field
                    :value="car.param.surcharge_time_from"
                    :name="'car[' + car.class_id + '].surcharge_time_from'"
                    v-slot="{ field }"
                  >
                    <input
                      v-bind="field"
                      :disabled="requestTime"
                      type="time"
                      class="w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 text-center disabled:bg-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green"
                      v-model="car.param.surcharge_time_from"
                    />
                  </Field>
                </td>
                <td class="h-full">
                  <Field
                    :value="car.param.surcharge_time_to"
                    :name="'car[' + car.class_id + '].surcharge_time_to'"
                    v-slot="{ field }"
                  >
                    <input
                      min="00:00"
                      max="23:59"
                      type="time"
                      v-bind="field"
                      :disabled="requestTime"
                      class="w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 text-center disabled:bg-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green"
                      v-model="car.param.surcharge_time_to"
                    />
                  </Field>
                </td>
                <td class="h-full">
                  <Field
                    :value="car.param.surcharge_plus_minus"
                    :name="'car[' + car.class_id + '].surcharge_plus_minus'"
                    v-slot="{ field }"
                  >
                    <select
                      v-bind="field"
                      class="w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 text-center disabled:bg-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green"
                      v-model="car.param.surcharge_plus_minus"
                      :disabled="requestTime"
                    >
                      <option value="plus">+</option>
                      <option value="minus">-</option>
                    </select>
                  </Field>
                </td>
                <td class="h-full">
                  <Field
                    :value="car.param.surcharge_coefficient"
                    :name="'car[' + car.class_id + '].surcharge_coefficient'"
                    v-slot="{ field }"
                  >
                    <input
                      v-bind="field"
                      type="number"
                      min="1"
                      max="200"
                      :disabled="requestTime"
                      class="w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 text-center disabled:bg-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green"
                      v-model="car.param.surcharge_coefficient"
                    />
                  </Field>
                </td>
                <td class="h-full">
                  <label
                    class="flex justify-center items-center h-full cursor-pointer rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5"
                  >
                    <Field
                      type="checkbox"
                      :name="'car[' + car.class_id + '].car_on_off'"
                      v-slot="{ field }"
                    >
                      <input
                        :disabled="requestTime"
                        v-bind="field"
                        :checked="car.param.car_on_off === 1"
                        class="h-6 w-6 disabled:bg-silver-fog appearance-none rounded-[5px] border border-lush-green bg-center bg-no-repeat p-0 duration-300 checked:bg-[url(@/assets/images/tick.svg)] checked:bg-lush-green checked:bg-blend-difference focus:ring-lush-green focus:bg-blend-difference"
                        type="checkbox"
                      />
                    </Field>
                  </label>
                </td>
                <td class="h-full">
                  <Field
                    :value="car.param.basic_exit_fee"
                    :name="'car[' + car.class_id + '].basic_exit_fee'"
                    v-slot="{ field }"
                  >
                    <input
                      v-bind="field"
                      :disabled="requestTime"
                      class="w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 text-center disabled:bg-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green"
                      v-model="car.param.basic_exit_fee"
                    />
                  </Field>
                </td>
                <td class="h-full">
                  <Field
                    :value="car.param.last_minute_fee"
                    :name="'car[' + car.class_id + '].last_minute_fee'"
                    v-slot="{ field }"
                  >
                    <input
                      v-bind="field"
                      :disabled="requestTime"
                      class="w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 text-center disabled:bg-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green"
                      v-model="car.param.last_minute_fee"
                    />
                  </Field>
                </td>
                <td class="h-full">
                  <Field
                    :value="car.param.last_second_fee"
                    :name="'car[' + car.class_id + '].last_second_fee'"
                    v-slot="{ field }"
                  >
                    <input
                      v-bind="field"
                      :disabled="requestTime"
                      class="w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 text-center disabled:bg-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green"
                      v-model="car.param.last_second_fee"
                    />
                  </Field>
                </td>
                <td class="h-full">
                  <Field
                    :value="car.param.price_per_km"
                    :name="'car[' + car.class_id + '].price_per_km'"
                    v-slot="{ field }"
                  >
                    <input
                      v-bind="field"
                      :disabled="requestTime"
                      class="w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 text-center disabled:bg-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green"
                      v-model="car.param.price_per_km"
                    />
                  </Field>
                </td>
                <td class="h-full">
                  <Field
                    :value="car.param.price_per_hr"
                    :name="'car[' + car.class_id + '].price_per_hr'"
                    v-slot="{ field }"
                  >
                    <input
                      v-bind="field"
                      :disabled="requestTime"
                      class="w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 text-center disabled:bg-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green"
                      v-model="car.param.price_per_hr"
                    />
                  </Field>
                </td>
                <td class="h-full">
                  <Field
                    :value="car.param.extra_km_rate"
                    :name="'car[' + car.class_id + '].extra_km_rate'"
                    v-slot="{ field }"
                  >
                    <input
                      v-bind="field"
                      :disabled="requestTime"
                      v-model="car.param.extra_km_rate"
                      class="w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 text-center disabled:bg-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green"
                    />
                  </Field>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <button
          v-if="checkPermission('rates.edit')"
          type="submit"
          :disabled="requestTime"
          class="w-full h-full rounded-full border border-lush-green bg-lush-green py-[22px] font-semibold leading-[1.1] text-midnight-ink duration-300 disabled:bg-transparent dark:disabled:text-white hover:bg-transparent dark:hover:text-white"
        >
          Update
        </button>
      </section>
    </Form>
  </main>
</template>

<script setup>
import { inject, onBeforeMount, ref } from 'vue'
import { Field, Form } from 'vee-validate'
import { useFetcher } from '@/compose/axios'
import { useUserStore } from '@/stores/user'

const rates = ref(null)

const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })
const menuCollapsed = inject('menuCollapsed')
const userStore = useUserStore()

const checkPermission = (permission) => {
  return userStore.checkPermission(permission)
}

const requestTime = ref(null)
const ratesSubmit = async (values) => {
  requestTime.value = true
  values['car'] = values['car'].map((arr) => {
    return { ...arr, car_on_off: arr.car_on_off === undefined ? 1 : 0 }
  })

  let payload = []

  values['car'].forEach((e, k) => {
    e.id = k

    payload.push(e)
  })

  await axiosInstance.post('/admin/rates', { car: payload }).then((response) => {
    rates.value = response.data.data

    requestTime.value = false
  })
}

onBeforeMount(() => {
  requestTime.value = false
  axiosInstance.get('/admin/rates').then((response) => {
    rates.value = response.data.data
  })
})
</script>

<style scoped></style>
